import {
    CLEAR_LISTINGS_ERRORS,
    CREATE_LISTINGS,
    DELETE_LISTINGS,
    DUPLICATE_LISTING,
    GET_LISTINGS_DETAILS,
    LISTINGS_LIST,
    LISTINGS_SIMPLE_LIST,
    SHARE_LISTING,
    UPDATE_LISTINGS,
    UPDATE_LISTINGS_STATUS,
} from "@/core/services/store/listings.module";
import {mapState} from "vuex";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {COMPLETIONSTATUSES_SIMPLE_LIST} from "@/core/services/store/completion_statuses.module";
import {bus} from "@/main";
import {AMENITIES_SIMPLE_LIST} from "@/core/services/store/amenities.module";
import {VIEWS_SIMPLE_LIST} from "@/core/services/store/views.module";
import {PURPOSES_SIMPLE_LIST} from "@/core/services/store/purposes.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {PROJECTUNITTYPES_SIMPLE_LIST} from "@/core/services/store/projectunittypes.module";
import {PROJECTTYPICALFLOORPLANS_SIMPLE_LIST} from "@/core/services/store/projecttypicalfloorplans.module";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";
import {SOURCES_SIMPLE_LIST} from "@/core/services/store/sources.module";
import {STAFF_LIST} from "@/core/services/store/users.module";
import {PUBLISHINGSTATUSES_SIMPLE_LIST} from "@/core/services/store/publishing_statuses.module";
import ViewsSelection from "@/view/pages/realstate/selections/ViewsSelection";
import LocationRemarksSelection from "@/view/pages/realstate/selections/LocationRemarksSelection";
import AmenitiesSelection from "@/view/pages/realstate/selections/AmenitiesSelection";
import RoadLocationsSelection from "@/view/pages/realstate/selections/RoadLocationsSelection";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import ListingFees from "@/view/pages/realstate/listings/inc/ListingFees";
import ListingFeatures from "@/view/pages/realstate/listings/inc/ListingFeatures";
import ListingDetails from "@/view/pages/realstate/listings/inc/ListingDetails";
import SharedListings from "@/view/pages/realstate/listings/inc/SharedListings";
import {FREQUENCIES_SIMPLE_LIST} from "@/core/services/store/frequencies.module";
import {GET_PROJECTUNITDETAILS_DETAILS_BY_UNIT_NUMBER} from "@/core/services/store/projectunitdetails.module";
import {ROADLOCATIONS_SIMPLE_LIST} from "@/core/services/store/road_locations.module";
import {LOCATIONREMARKS_SIMPLE_LIST} from "@/core/services/store/locationremarks.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import {DEVELOPMENTS_SIMPLE_LIST, DEVELOPMENTS_WITH_COUNT} from "@/core/services/store/developments.module";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {SAVE_REPORT} from "@/core/services/store/dashboard.module";

const listingsScript = {
    name: 'listings',
    components: {
        ModuleMedia,
        ModuleUploadedFiles,
        LocationRemarksSelection,
        ViewsSelection,
        RoadLocationsSelection,
        AmenitiesSelection,
        ListingFees,
        ListingDetails,
        SharedListings,
        ListingFeatures,
        ModuleLinks
    },
    props: ['offerListings'],
    data() {
        return {
            mapInput: '',
            center: {},
            locationMarkers: [],
            existingPlace: null,
            headers: [
                {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
                {text: 'Country', value: 'country.title', name: 'country.title', filterable: false, sortable: false},
                {text: 'City', value: 'city.title', name: 'city.title', filterable: false, sortable: false},
                {text: "Created", value: "created_at", name: "created_at", filterable: false, sortable: true},
                {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
                {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
            ],
            isLoaded: true,
            isLoadedContacts: false,
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            projectTypesFilter: [],
            completionStatusesFilter: [],
            publishingStatusesFilter: [],
            iutFilter: [],
            eutFilter: [],
            tfpFilter: [],
            sourcesFilter: [],
            purposesFilter: [],
            ptFilter: [],
            projectsFilter: [],
            isProjectsLoaded: false,
            amenitiesFilter: [],
            amenitiesIdsFilter: [],
            viewsFilter: [],
            viewsIdsFilter: [],
            developersFilter: [],
            isDevelopersLoaded: false,
            coolingProvidersFilter: [],
            isCoolingProvidersLoaded: false,
            ownersFilter: [],
            isOwnersLoaded: false,
            kLFilter: [],
            isKLLoaded: false,
            unitNumberFilter: [],
            isUnitNumberLoaded: false,
            tenantsFilter: [],
            isTenantsLoaded: false,
            staffFilter: [],
            rlFilter: [],
            rlIdsFilter: [],
            locationRemarksFilter: [],
            locationRemarksIdsFilter: [],
            rfFilter: [],
            strfFilter: [],
            columns: [],
            validationRules: {
                valid: true,
                titleRules: [
                    v => !!v || "This field is required",
                    v => (v && v.length >= 3) || "Must be greater than 3 characters",
                    v => (v && v.length <= 200) || "Must be less than 200 characters"
                ],
                unitNoRules: [
                    v => !!v || "This field is required",
                    v => (v && v.length >= 1) || "Must be greater than 1 characters",
                    v => (v && v.length <= 30) || "Must be less than 30 characters"
                ],
                selectRule: [
                    (v) => {
                        if (!v || v.length < 1)
                            return 'Select an option';
                        else return true;
                    }
                ],
                multipleRules: [
                    v => {
                        if (!v || v.length < 1) return "Select Master Developer";
                        else return true;
                    }
                ]
            },
            items: [],
            dialog: false,
            sumDialog: false,
            showUnitNo: 0,
            reportLink: '',
            editedItem: {
                rent_agency_fee_tenant: '',
                rent_agency_fee_landlord: '',
                rent_security_fee: '',
                sale_paid_of_original_price: '',
                sale_agency_fee_buyer: '',
                sale_agency_fee_seller: '',
                sale_dld_fee: '',
                sale_transfer_fee_buyer: '',
                sale_transfer_fee_seller: '',
                sale_legal_fee: '',
                sale_original_price: '',
                sale_premium: '',
                sale_payable_now: '',
                sale_payable_now_developer: ''
            },
            showUploadedFiles: false,
            yscValue: '',
            yscMultiplyValue: '',
            mccValue: '',
            mccMultiplyValue: '',
            chargesMultiplies: [
                {value: "0", title: 'Built up area sq ft'},
                {value: "1", title: 'Plot area sq ft'},
            ],
            discountOnFilter: [
                {value: "Original Price", title: 'Original Price'},
                {value: "Paid Of Original Price", title: 'Paid Of Original Price'},
            ],
            masterPropertyType: '',
            parentPropertyType: '',
            locationRef: 'Project',
            locationRefModel: '',
            builtUpAreaUnit: '',
            plotAreaUnit: '',
            warehouseOfficeSpaceUnit: '',
            warehouseOpenYardUnit: '',
            warehouseStorageAreaUnit: '',
            dupDialog: false,
            dupItem: '',
            duplicatedItem: {p_id: '', p_type_id: ''},
            listingsFilter: [],
            isListingsLoaded: false,
            searchFormData: {},
            developmentsFilter: [],
            isDevelopmentsLoaded: false,
            detailDialog: false,
            detailItem: {},
            advSHitted: false,
            shareDialog: false,
            listingSharingIds: [],
            treeDialog: false,
            treeItems: [],
            offerListingsSelection: [],
        }
    },
    watch: {
        // params: {
        //     handler() {
        //         this.loadData().then(response => {
        //             this.items = response.items;
        //             this.totalRecords = response.total;
        //         });
        //     },
        //     deep: true
        // },
        dupDialog(val) {
            if (!val) this.dupItem = '';
            if (!val) this.duplicatedItem = {p_id: '', p_type_id: ''}
        },
        dialog(val) {
            !val && this.$store.dispatch(CLEAR_LISTINGS_ERRORS);
            if (!val) {
                let that = this;
                setTimeout(function () {
                    that.editedItem.description = '';
                }, 500)
            }
            if (!val) this.editedItem = {
                rent_agency_fee_tenant: '',
                rent_agency_fee_landlord: '',
                rent_security_fee: '',
                sale_paid_of_original_price: '',
                sale_agency_fee_buyer: '',
                sale_agency_fee_seller: '',
                sale_dld_fee: '',
                sale_transfer_fee_buyer: '',
                sale_transfer_fee_seller: '',
                sale_legal_fee: '',
                sale_original_price: '',
                sale_premium: '',
                sale_payable_now: '',
                sale_payable_now_developer: ''
            };
            if (!val) this.showUploadedFiles = false;
            if (!val) this.center = {lat: 0, lng: 0};
            if (!val) this.locationMarkers = [];
            if (!val) this.mapInput = '';
            if (!val) this.$store.state.listings.latitude = '';
            if (!val) this.$store.state.listings.longitude = '';
            if (!val) this.$store.state.listings.yearlyServiceCharges = '';
            if (!val) this.$store.state.listings.monthlyCoolingCharges = '';
            if (!val) this.activeTab = 0;
            if (!val) bus.$emit("moduleFilesDeletedByModal");
            if (!val) bus.$emit("listingFeesDeletedByModal");
            if (!val) bus.$emit("listingFeaturesDeletedByModal");
            if (!val) this.$store.state.selections.selectedViewIds = []
            if (!val) this.$store.state.selections.selectedLRIds = []
            if (!val) this.$store.state.selections.selectedAmenityIds = []
            if (!val) this.$store.state.selections.selectedRLIds = []
            if (val) {
                this.builtUpAreaUnit = this.builtUpAreaUnit ? this.builtUpAreaUnit : this.$store.state.auth.user.measurement_unit
                this.plotAreaUnit = this.plotAreaUnit ? this.plotAreaUnit : this.$store.state.auth.user.measurement_unit
                this.warehouseOfficeSpaceUnit = this.warehouseOfficeSpaceUnit ? this.warehouseOfficeSpaceUnit : this.$store.state.auth.user.measurement_unit
                this.warehouseOpenYardUnit = this.warehouseOpenYardUnit ? this.warehouseOpenYardUnit : this.$store.state.auth.user.measurement_unit
                this.warehouseStorageAreaUnit = this.warehouseStorageAreaUnit ? this.warehouseStorageAreaUnit : this.$store.state.auth.user.measurement_unit
            } else {
                this.builtUpAreaUnit = ''
                this.plotAreaUnit = ''
                this.warehouseOfficeSpaceUnit = ''
                this.warehouseOpenYardUnit = ''
                this.warehouseStorageAreaUnit = ''
            }
        },
        shareDialog(val) {
            if (val) {
                const index = this.staffFilter.findIndex(element => {
                    if (element.id === this.$store.state.auth.user.id) {
                        return true;
                    }
                });
                if (index !== -1) this.staffFilter.splice(index, 1);
            }
            if (!val) this.listingSharingIds = [];
        },
        offerListingsSelection(val) {
            this.$emit('offerListingsItem', val)
        },
        '$route'(to, from) {
            if (to.name === 'listings') {
                this.refreshData(false)
            }
        }
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                '&columns[' + i + '][data]=' + this.headers[i].value +
                '&columns[' + i + '][name]=' + this.headers[i].name +
                '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
                '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
                '&columns[' + i + '][search][value]=' +
                '&columns[' + i + '][search][regex]=' + false
            );
        }
        this.searchFormData.showSearch = 0
        if (this.$route.query.hasOwnProperty('addModule')) {
            this.dialog = true;
        }
        if (this.$route.query.hasOwnProperty('rel')) {
            this.searchFormData.relId = parseInt(this.$route.query.rel);
        }
        // if (this.$route.query.hasOwnProperty('development')) {
        //     this.searchFormData.developmentSearch = parseInt(this.$route.query.development);
        // }
        this.showTree(0, 'all');
        this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
            this.getPurposes();
        });
    },
    methods: {
        initMarker(loc) {
            this.existingPlace = loc;
        },
        addLocationMarker() {
            if (this.existingPlace) {
                this.locationMarkers = [];
                const marker = {
                    lat: this.existingPlace.geometry.location.lat(),
                    lng: this.existingPlace.geometry.location.lng()
                };
                this.$store.state.listings.latitude = this.existingPlace.geometry.location.lat();
                this.$store.state.listings.longitude = this.existingPlace.geometry.location.lng();
                this.editedItem.location = this.existingPlace.formatted_address;
                this.mapInput = '';
                // this.editedItem.latitude = this.existingPlace.geometry.location.lat();
                // this.editedItem.longitude = this.existingPlace.geometry.location.lng();
                this.locationMarkers.push({position: marker});
                this.center = marker;
                this.existingPlace = null;
            }
        },
        setLatLong(e) {
            this.existingPlace = null;
            this.mapInput = '';
            let latLng = e.latLng.toJSON()
            this.$store.state.listings.latitude = latLng.lat;
            this.$store.state.listings.longitude = latLng.lng;
            const marker = {
                lat: latLng.lat,
                lng: latLng.lng
            };
            this.locationMarkers = [{position: marker}];
            this.center = marker;
        },
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            this.items = [];
            this.mainDataTableSelection = [];
            this.searchFormData.is_listing = 1
            return new Promise((resolve) => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per page
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.getHeaderIndex(params.sortBy[0]);
                query += '&' + searchFormDataQuery
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 3 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "desc" : "asc");
                this.$store.dispatch(LISTINGS_LIST, {q: query, columns: this.columns}).then((res) => {
                    // this.draw++;
                    let items = res.records.data;
                    let total = res.records.recordsFiltered;
                    this.loading = !this.loading;
                    this.isLoaded = !this.isLoaded;
                    resolve({
                        items,
                        total
                    });
                });
            });
        },
        refreshData(loadDep = true) {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
                this.advSHitted = !this.advSHitted
                if (loadDep) this.getPurposes();
            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        hitAdvanceSearch(reset = false) {
            this.pagination.page = 1
            if (reset) {
                this.searchFormData = {};
                this.showTree(0, 'all');
            }
            this.advSHitted = !this.advSHitted;
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },
        showEditDialog(type, item) {
            this.$store.dispatch(GET_LISTINGS_DETAILS, item.id).then((data) => {
                if (type === 'edit') {
                    this.editedItem = data.records || {
                        rent_agency_fee_tenant: '',
                        rent_agency_fee_landlord: '',
                        rent_security_fee: '',
                        sale_paid_of_original_price: '',
                        sale_agency_fee_buyer: '',
                        sale_agency_fee_seller: '',
                        sale_dld_fee: '',
                        sale_transfer_fee_buyer: '',
                        sale_transfer_fee_seller: '',
                        sale_legal_fee: '',
                        sale_original_price: '',
                        sale_premium: '',
                        sale_payable_now: '',
                        sale_payable_now_developer: ''
                    };
                    this.mapInput = '';
                    this.builtUpAreaUnit = data.records.built_up_area_unit
                    this.plotAreaUnit = data.records.plot_area_unit
                    this.warehouseOfficeSpaceUnit = data.records.warehouse_office_space_unit
                    this.warehouseOpenYardUnit = data.records.warehouse_open_yard_unit
                    this.warehouseStorageAreaUnit = data.records.warehouse_storage_area_unit
                    this.$store.state.listings.latitude = data.records.latitude ? parseFloat(data.records.latitude) : 0;
                    this.$store.state.listings.longitude = data.records.longitude ? parseFloat(data.records.longitude) : 0;
                    if (this.editedItem.max_sale_price > 500000) {
                        this.editedItem.sale_trustee_office_fee = "4,200"
                    }
                    if (this.editedItem.max_sale_price < 500000) {
                        this.editedItem.sale_trustee_office_fee = "2,200"
                    }
                    if (!this.editedItem.sale_dev_noc_fee && this.editedItem.completion_status_id === 7) {
                        this.editedItem.sale_dev_noc_fee = "500"
                    }
                    if (!this.editedItem.sale_dev_noc_fee && this.editedItem.completion_status_id && this.editedItem.completion_status_id !== 7) {
                        this.editedItem.sale_dev_noc_fee = "5,000"
                    }
                    const marker = {
                        lat: data.records.latitude ? parseFloat(data.records.latitude) : 0,
                        lng: data.records.longitude ? parseFloat(data.records.longitude) : 0
                    };
                    this.locationMarkers.push({position: marker});
                    this.center = marker;
                    if (data.records.project) {
                        this.locationRefModel = 'project';
                        this.projectsFilter = [data.records.project];
                        this.getInternalUnitTypes(data.records.project_id);
                    }
                    if (data.records.development) {
                        this.locationRefModel = 'development';
                        this.projectsFilter = [data.records.development];
                    }
                    if (data.records.developer) {
                        this.developersFilter = [data.records.developer];
                    }
                    if (data.records.tenant) {
                        this.tenantsFilter = [data.records.tenant];
                    }
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(data.records.yearly_service_charges)
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(data.records.monthly_cooling_charges)
                    this.editedItem.min_rent_per_annum = this.addTSBV(data.records.min_rent_per_annum)
                    this.editedItem.max_rent_per_annum = this.addTSBV(data.records.max_rent_per_annum)
                    this.editedItem.rent_agency_fee_tenant = this.addTSBV(data.records.rent_agency_fee_tenant)
                    this.editedItem.rent_agency_fee_landlord = this.addTSBV(data.records.rent_agency_fee_landlord)
                    this.editedItem.rent_security_fee = this.addTSBV(data.records.rent_security_fee)
                    this.editedItem.total_rent = this.addTSBV(data.records.total_rent)
                    this.editedItem.min_sale_price = this.addTSBV(data.records.min_sale_price)
                    this.editedItem.max_sale_price = this.addTSBV(data.records.max_sale_price)
                    this.editedItem.sale_original_price = this.addTSBV(data.records.sale_original_price)
                    this.editedItem.sale_paid_of_original_price = this.addTSBV(data.records.sale_paid_of_original_price)
                    this.editedItem.sale_discount = this.addTSBV(data.records.sale_discount)
                    this.editedItem.sale_premium = this.addTSBV(data.records.sale_premium)
                    this.editedItem.sale_agency_fee_buyer = this.addTSBV(data.records.sale_agency_fee_buyer)
                    this.editedItem.sale_agency_fee_seller = this.addTSBV(data.records.sale_agency_fee_seller)
                    this.editedItem.sale_dld_fee = this.addTSBV(data.records.sale_dld_fee)
                    this.editedItem.sale_transfer_fee_buyer = this.addTSBV(data.records.sale_transfer_fee_buyer)
                    this.editedItem.sale_transfer_fee_seller = this.addTSBV(data.records.sale_transfer_fee_seller)
                    this.editedItem.sale_dev_noc_fee = this.addTSBV(data.records.sale_dev_noc_fee)
                    this.editedItem.sale_trustee_office_fee = this.addTSBV(data.records.sale_trustee_office_fee)
                    this.editedItem.sale_legal_fee = this.addTSBV(data.records.sale_legal_fee)
                    this.editedItem.sale_payable_now = this.addTSBV(data.records.sale_payable_now)
                    this.editedItem.sale_payable_now_developer = this.addTSBV(data.records.sale_payable_now_developer)
                    this.editedItem.tenancy_rent = this.addTSBV(data.records.tenancy_rent)
                    this.ownersFilter = data.records.owners;
                    this.kLFilter = data.records.key_locations;
                    this.coolingProvidersFilter = data.records.cooling_providers;
                    this.editedItem.description = data.records.description ? data.records.description : '';
                    this.$store.state.selections.selectedViewIds = this.editedItem.view_ids
                    this.$store.state.selections.selectedLRIds = this.editedItem.location_remark_ids
                    this.$store.state.selections.selectedAmenityIds = this.editedItem.amenity_ids
                    this.$store.state.selections.selectedRLIds = this.editedItem.road_location_ids
                    if (this.editedItem.purpose_id === 2) {
                        this.getAmountByPercentageWe("5", this.editedItem.max_rent_per_annum, 'rent_agency_fee_tenant')
                        this.editedItem.rent_agency_fee_tenant_percentage = "5"
                    } else {
                        this.getAmountByPercentageWe("2", this.editedItem.max_sale_price, 'sale_agency_fee_buyer')
                        this.getAmountByPercentageWe("2", this.editedItem.max_sale_price, 'sale_agency_fee_seller')
                        this.getAmountByPercentageWe("4", this.editedItem.max_sale_price, 'sale_dld_fee')
                        this.editedItem.sale_agency_fee_buyer_percentage = "2"
                        this.editedItem.sale_agency_fee_seller_percentage = "2"
                        this.editedItem.sale_dld_fee_percentage = "4"
                    }
                    this.dialog = true;
                    this.showUploadedFiles = true;
                } else if (type === 'price') {
                    this.editedItem = data.records || {
                        rent_agency_fee_tenant: '',
                        rent_agency_fee_landlord: '',
                        rent_security_fee: '',
                        sale_paid_of_original_price: '',
                        sale_agency_fee_buyer: '',
                        sale_agency_fee_seller: '',
                        sale_dld_fee: '',
                        sale_transfer_fee_buyer: '',
                        sale_transfer_fee_seller: '',
                        sale_legal_fee: '',
                        sale_original_price: '',
                        sale_premium: '',
                        sale_payable_now: '',
                        sale_payable_now_developer: ''
                    };
                    if (this.editedItem.max_sale_price > 500000) {
                        this.editedItem.sale_trustee_office_fee = "4,200"
                    }
                    if (this.editedItem.max_sale_price < 500000) {
                        this.editedItem.sale_trustee_office_fee = "2,200"
                    }
                    if (!this.editedItem.sale_dev_noc_fee && this.editedItem.completion_status_id === 7) {
                        this.editedItem.sale_dev_noc_fee = "500"
                    }
                    if (!this.editedItem.sale_dev_noc_fee && this.editedItem.completion_status_id && this.editedItem.completion_status_id !== 7) {
                        this.editedItem.sale_dev_noc_fee = "5,000"
                    }
                    this.editedItem.min_rent_per_annum = this.addTSBV(data.records.min_rent_per_annum)
                    this.editedItem.max_rent_per_annum = this.addTSBV(data.records.max_rent_per_annum)
                    this.editedItem.rent_agency_fee_tenant = this.addTSBV(data.records.rent_agency_fee_tenant)
                    this.editedItem.rent_agency_fee_landlord = this.addTSBV(data.records.rent_agency_fee_landlord)
                    this.editedItem.rent_security_fee = this.addTSBV(data.records.rent_security_fee)
                    this.editedItem.total_rent = this.addTSBV(data.records.total_rent)
                    this.editedItem.min_sale_price = this.addTSBV(data.records.min_sale_price)
                    this.editedItem.max_sale_price = this.addTSBV(data.records.max_sale_price)
                    this.editedItem.sale_original_price = this.addTSBV(data.records.sale_original_price)
                    this.editedItem.sale_paid_of_original_price = this.addTSBV(data.records.sale_paid_of_original_price)
                    this.editedItem.sale_discount = this.addTSBV(data.records.sale_discount)
                    this.editedItem.sale_premium = this.addTSBV(data.records.sale_premium)
                    this.editedItem.sale_agency_fee_buyer = this.addTSBV(data.records.sale_agency_fee_buyer)
                    this.editedItem.sale_agency_fee_seller = this.addTSBV(data.records.sale_agency_fee_seller)
                    this.editedItem.sale_dld_fee = this.addTSBV(data.records.sale_dld_fee)
                    this.editedItem.sale_transfer_fee_buyer = this.addTSBV(data.records.sale_transfer_fee_buyer)
                    this.editedItem.sale_transfer_fee_seller = this.addTSBV(data.records.sale_transfer_fee_seller)
                    this.editedItem.sale_dev_noc_fee = this.addTSBV(data.records.sale_dev_noc_fee)
                    this.editedItem.sale_trustee_office_fee = this.addTSBV(data.records.sale_trustee_office_fee)
                    this.editedItem.sale_legal_fee = this.addTSBV(data.records.sale_legal_fee)
                    this.editedItem.sale_payable_now = this.addTSBV(data.records.sale_payable_now)
                    this.editedItem.sale_payable_now_developer = this.addTSBV(data.records.sale_payable_now_developer)
                    this.editedItem.tenancy_rent = this.addTSBV(data.records.tenancy_rent)
                    this.ownersFilter = data.records.owners;
                    this.kLFilter = data.records.key_locations;
                    this.coolingProvidersFilter = data.records.cooling_providers;
                    this.editedItem.description = data.records.description ? data.records.description : '';
                    this.$store.state.selections.selectedViewIds = this.editedItem.view_ids
                    this.$store.state.selections.selectedLRIds = this.editedItem.location_remark_ids
                    this.$store.state.selections.selectedAmenityIds = this.editedItem.amenity_ids
                    this.$store.state.selections.selectedRLIds = this.editedItem.road_location_ids
                    // if (this.editedItem.purpose_id === 2) {
                    //     this.getAmountByPercentageWe("5", this.editedItem.max_rent_per_annum, 'rent_agency_fee_tenant')
                    //     this.editedItem.rent_agency_fee_tenant_percentage = "5"
                    // } else {
                    //     this.getAmountByPercentageWe("2", this.editedItem.max_sale_price, 'sale_agency_fee_buyer')
                    //     this.getAmountByPercentageWe("2", this.editedItem.max_sale_price, 'sale_agency_fee_seller')
                    //     this.getAmountByPercentageWe("4", this.editedItem.max_sale_price, 'sale_dld_fee')
                    //     this.editedItem.sale_agency_fee_buyer_percentage = "2"
                    //     this.editedItem.sale_agency_fee_seller_percentage = "2"
                    //     this.editedItem.sale_dld_fee_percentage = "4"
                    // }
                    let domain = '';
                    if (this.$store.state.auth.user.team_id === 1) {
                        domain = 'dubricks.com'
                    }
                    if (this.$store.state.auth.user.team_id === 2) {
                        domain = 'afsrealestate.com'
                    }
                    let url = 'https://' + domain + '/listing-price-report?ref=' + this.editedItem.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
                    this.generateReport(url)
                } else {
                    this.detailItem = data.records || {};
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(data.records.yearly_service_charges)
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(data.records.monthly_cooling_charges)
                    this.detailItem.min_rent_per_annum = this.addTSBV(data.records.min_rent_per_annum)
                    this.detailItem.max_rent_per_annum = this.addTSBV(data.records.max_rent_per_annum)
                    this.detailItem.rent_agency_fee_tenant = this.addTSBV(data.records.rent_agency_fee_tenant)
                    this.detailItem.rent_agency_fee_landlord = this.addTSBV(data.records.rent_agency_fee_landlord)
                    this.detailItem.rent_security_fee = this.addTSBV(data.records.rent_security_fee)
                    this.detailItem.total_rent = this.addTSBV(data.records.total_rent)
                    this.detailItem.min_sale_price = this.addTSBV(data.records.min_sale_price)
                    this.detailItem.max_sale_price = this.addTSBV(data.records.max_sale_price)
                    this.detailItem.sale_original_price = this.addTSBV(data.records.sale_original_price)
                    this.detailItem.sale_paid_of_original_price = this.addTSBV(data.records.sale_paid_of_original_price)
                    this.detailItem.sale_discount = this.addTSBV(data.records.sale_discount)
                    this.detailItem.sale_premium = this.addTSBV(data.records.sale_premium)
                    this.detailItem.sale_agency_fee_buyer = this.addTSBV(data.records.sale_agency_fee_buyer)
                    this.detailItem.sale_agency_fee_seller = this.addTSBV(data.records.sale_agency_fee_seller)
                    this.detailItem.sale_dld_fee = this.addTSBV(data.records.sale_dld_fee)
                    this.detailItem.sale_transfer_fee_buyer = this.addTSBV(data.records.sale_transfer_fee_buyer)
                    this.detailItem.sale_transfer_fee_seller = this.addTSBV(data.records.sale_transfer_fee_seller)
                    this.detailItem.sale_legal_fee = this.addTSBV(data.records.sale_legal_fee)
                    this.detailItem.sale_trustee_office_fee = this.addTSBV(data.records.sale_trustee_office_fee)
                    this.detailItem.sale_dev_noc_fee = this.addTSBV(data.records.sale_dev_noc_fee)
                    this.detailItem.sale_payable_now = this.addTSBV(data.records.sale_payable_now)
                    this.detailItem.sale_payable_now_developer = this.addTSBV(data.records.sale_payable_now_developer)
                    this.detailItem.tenancy_rent = this.addTSBV(data.records.tenancy_rent)
                    this.detailItem.description = data.records.description ? data.records.description : '';
                    this.detailDialog = !this.detailDialog;
                }
            });
        },
        closeDialog() {
            this.dialog = !this.dialog;
        },
        saveItem(item) {
            if (this.$refs.form.validate()) {
                let method = CREATE_LISTINGS;
                let id = item.id;
                let data = {
                    data: item
                };
                this.$set(item, "is_listing", 1);
                this.$set(item, "location_ref", this.locationRefModel);
                this.$set(item, "listing_files", this.$store.state.uploads.uploadedStateOtherFiles);
                this.$set(item, "listing_links", this.$store.state.uploads.uploadedStateLinks);
                this.$set(item, "listing_fees", this.$store.state.listings.uploadedListingFees);
                this.$set(item, "listing_features", this.$store.state.listings.uploadedListingFeatures);
                this.$set(item, "latitude", this.$store.state.listings.latitude);
                this.$set(item, "longitude", this.$store.state.listings.longitude);
                // this.$set(item, "yearly_service_charges", this.$store.state.listings.yearlyServiceCharges.replace(/,(?=\d{3})/g, ''));
                // this.$set(item, "monthly_cooling_charges", this.$store.state.listings.monthlyCoolingCharges.replace(/,(?=\d{3})/g, ''));
                item.yearly_service_charges = item.yearly_service_charges ? item.yearly_service_charges.replace(/,(?=\d{3})/g, '') : ''
                item.monthly_cooling_charges = item.monthly_cooling_charges ? item.monthly_cooling_charges.replace(/,(?=\d{3})/g, '') : ''
                item.min_rent_per_annum = item.min_rent_per_annum ? item.min_rent_per_annum.replace(/,(?=\d{3})/g, '') : ''
                item.max_rent_per_annum = item.max_rent_per_annum ? item.max_rent_per_annum.replace(/,(?=\d{3})/g, '') : ''
                item.rent_agency_fee_tenant = item.rent_agency_fee_tenant ? item.rent_agency_fee_tenant.replace(/,(?=\d{3})/g, '') : ''
                item.rent_agency_fee_landlord = item.rent_agency_fee_landlord ? item.rent_agency_fee_landlord.replace(/,(?=\d{3})/g, '') : ''
                item.rent_security_fee = item.rent_security_fee ? item.rent_security_fee.replace(/,(?=\d{3})/g, '') : ''
                item.total_rent = item.total_rent ? item.total_rent.replace(/,(?=\d{3})/g, '') : ''
                item.min_sale_price = item.min_sale_price ? item.min_sale_price.replace(/,(?=\d{3})/g, '') : ''
                item.max_sale_price = item.max_sale_price ? item.max_sale_price.replace(/,(?=\d{3})/g, '') : ''
                item.sale_original_price = item.sale_original_price ? item.sale_original_price.replace(/,(?=\d{3})/g, '') : ''
                item.sale_paid_of_original_price = item.sale_paid_of_original_price ? item.sale_paid_of_original_price.replace(/,(?=\d{3})/g, '') : ''
                item.sale_discount = item.sale_discount ? item.sale_discount.replace(/,(?=\d{3})/g, '') : ''
                item.sale_premium = item.sale_premium ? item.sale_premium.replace(/,(?=\d{3})/g, '') : ''
                item.sale_agency_fee_buyer = item.sale_agency_fee_buyer ? item.sale_agency_fee_buyer.replace(/,(?=\d{3})/g, '') : ''
                item.sale_agency_fee_seller = item.sale_agency_fee_seller ? item.sale_agency_fee_seller.replace(/,(?=\d{3})/g, '') : ''
                item.sale_dld_fee = item.sale_dld_fee ? item.sale_dld_fee.replace(/,(?=\d{3})/g, '') : ''
                item.sale_transfer_fee_buyer = item.sale_transfer_fee_buyer ? item.sale_transfer_fee_buyer.replace(/,(?=\d{3})/g, '') : ''
                item.sale_transfer_fee_seller = item.sale_transfer_fee_seller ? item.sale_transfer_fee_seller.replace(/,(?=\d{3})/g, '') : ''
                item.sale_trustee_office_fee = item.sale_trustee_office_fee ? item.sale_trustee_office_fee.replace(/,(?=\d{3})/g, '') : ''
                item.sale_dev_noc_fee = item.sale_dev_noc_fee ? item.sale_dev_noc_fee.replace(/,(?=\d{3})/g, '') : ''
                item.sale_legal_fee = item.sale_legal_fee ? item.sale_legal_fee.replace(/,(?=\d{3})/g, '') : ''
                item.sale_payable_now = item.sale_payable_now ? item.sale_payable_now.replace(/,(?=\d{3})/g, '') : ''
                item.sale_payable_now_developer = item.sale_payable_now_developer ? item.sale_payable_now_developer.replace(/,(?=\d{3})/g, '') : ''
                item.tenancy_rent = item.tenancy_rent ? item.tenancy_rent.replace(/,(?=\d{3})/g, '') : ''
                this.$set(item, "built_up_area_unit", this.builtUpAreaUnit);
                this.$set(item, "plot_area_unit", this.plotAreaUnit);
                this.$set(item, "warehouse_office_space_unit", this.warehouseOfficeSpaceUnit);
                this.$set(item, "warehouse_open_yard_unit", this.warehouseOpenYardUnit);
                this.$set(item, "warehouse_storage_area_unit", this.warehouseStorageAreaUnit);
                this.$set(item, "view_ids", this.$store.state.selections.selectedViewIds);
                this.$set(item, "location_remark_ids", this.$store.state.selections.selectedLRIds);
                this.$set(item, "amenity_ids", this.$store.state.selections.selectedAmenityIds);
                this.$set(item, "road_location_ids", this.$store.state.selections.selectedRLIds);
                if (id) {
                    method = UPDATE_LISTINGS;
                }
                this.$store.dispatch(CLEAR_LISTINGS_ERRORS);
                this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
                    // if (method === 'update_listings') {
                    //     this.showUploadedFiles = false;
                    //     bus.$emit("moduleFilesDeletedByModal");
                    //     bus.$emit("listingFeesDeletedByModal");
                    //     bus.$emit("listingFeaturesDeletedByModal");
                    //     this.showEditDialog(item);
                    // } else {
                    //     this.dialog = false;
                    // }
                    this.loadData().then(response => {
                        this.items = response.items;
                        this.totalRecords = response.total;
                    });
                    this.dialog = !this.dialog;
                    if (this.$route.query.hasOwnProperty('addModule')) {
                        window.close();
                    }
                })
            }
        },
        deleteItem(item) {
            let that = this;
            this.$store.dispatch(SET_CONFIRMATION, {
                title: "Are you sure?",
                msg: "You want to delete this listing?",
                clr: 'red',
                callback: function () {
                    let id = item.id;
                    this.$store.dispatch(DELETE_LISTINGS, {
                        'id': id
                    }).then(() => {
                        that.loadData().then(response => {
                            that.items = response.items;
                            that.totalRecords = response.total;
                        });
                    });
                }
            });
        },
        cSChange(evt) {
            // if (evt) {
            //     if (evt === 7) {
            //         this.editedItem.sale_dev_noc_fee = "500"
            //     } else {
            //         this.editedItem.sale_dev_noc_fee = "5,000"
            //     }
            // } else {
            //     this.editedItem.sale_dev_noc_fee = ""
            // }
        },
        getPurposes(loadNext = true) {
            this.$store.dispatch(PURPOSES_SIMPLE_LIST).then((data) => {
                this.purposesFilter = data.records.purposesList;
                if (loadNext) this.getPropertyTypes()
            });
        },
        getPropertyTypes(loadNext = true) {
            let that = this;
            this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 1}).then((data) => {
                data.records.developmentTypesList.forEach(function (v) {
                    if (v.parent_id) {
                        that.ptFilter.push(v)
                    }
                })
                if (loadNext) this.getCompletionStatuses()
            });
        },
        getCompletionStatuses(loadNext = true) {
            this.$store.dispatch(COMPLETIONSTATUSES_SIMPLE_LIST, {type: 'Properties'}).then((data) => {
                this.completionStatusesFilter = data.records.completionStatusesList;
                if (loadNext) this.getPublishingStatuses()
            });
        },
        getPublishingStatuses(loadNext = true) {
            this.$store.dispatch(PUBLISHINGSTATUSES_SIMPLE_LIST).then((data) => {
                this.publishingStatusesFilter = data.records.publishingStatusesList;
                if (loadNext) this.getSources()
            });
        },
        getSources(loadNext = true) {
            this.$store.dispatch(SOURCES_SIMPLE_LIST).then((data) => {
                this.sourcesFilter = data.records.sourcesList;
                if (loadNext) this.getViews()
            });
        },
        getViews(loadNext = true) {
            this.$store.dispatch(VIEWS_SIMPLE_LIST, {type: 'Properties'}).then((data) => {
                this.viewsFilter = data.records.viewsList;
                let that = this;
                this.viewsFilter.forEach(function (v) {
                    if (v.children.length > 0) {
                        v.children.forEach(function (vv) {
                            that.viewsIdsFilter.push(vv.id)
                        })
                    }
                })
                if (loadNext) this.getAmenities()
            });
        },
        getAmenities(loadNext = true) {
            this.$store.dispatch(AMENITIES_SIMPLE_LIST, {type: 'Properties'}).then((data) => {
                this.amenitiesFilter = data.records.amenitiesList;
                let that = this;
                this.amenitiesFilter.forEach(function (v) {
                    if (v.children.length > 0) {
                        v.children.forEach(function (vv) {
                            that.amenitiesIdsFilter.push(vv.id)
                        })
                    }
                })
                if (loadNext) this.getRoadLocations()
            });
        },
        getRoadLocations(loadNext = true) {
            this.$store.dispatch(ROADLOCATIONS_SIMPLE_LIST, {type: 'Properties'}).then((data) => {
                this.rlFilter = data.records.roadLocationsList;
                let that = this;
                this.rlFilter.forEach(function (v) {
                    if (v.children.length > 0) {
                        v.children.forEach(function (vv) {
                            that.rlIdsFilter.push(vv.id)
                        })
                    }
                })
                if (loadNext) this.getLocationRemarks()
            });
        },
        getLocationRemarks(loadNext = true) {
            this.$store.dispatch(LOCATIONREMARKS_SIMPLE_LIST, {type: 'Properties'}).then(data => {
                this.locationRemarksFilter = data.records.locationRemarksList;
                let that = this;
                this.locationRemarksFilter.forEach(function (v) {
                    if (v.children.length > 0) {
                        v.children.forEach(function (vv) {
                            that.locationRemarksIdsFilter.push(vv.id)
                        })
                    }
                })
                if (loadNext) this.getStaff()
            });
        },
        getStaff(loadNext = true) {
            this.$store.dispatch(STAFF_LIST).then((data) => {
                this.staffFilter = data.records.staffList;
                if (loadNext) this.getRentFrequencies()
            });
        },
        getRentFrequencies(loadNext = true) {
            this.$store.dispatch(FREQUENCIES_SIMPLE_LIST, {type: 'Full Term'}).then((data) => {
                this.rfFilter = data.records.frequenciesList;
                if (loadNext) this.getShortTermRentFrequencies()
            });
        },
        getShortTermRentFrequencies() {
            this.$store.dispatch(FREQUENCIES_SIMPLE_LIST, {type: 'Short Term'}).then((data) => {
                this.strfFilter = data.records.frequenciesList;
            });
        },
        getInternalUnitTypes(pId) {
            this.$store.dispatch(PROJECTUNITTYPES_SIMPLE_LIST, {project_id: pId, type: '0'}).then((data) => {
                this.iutFilter = data.records.projectUnitTypesList;
                this.getExternalUnitTypes(pId)
            });
        },
        getExternalUnitTypes(pId) {
            this.$store.dispatch(PROJECTUNITTYPES_SIMPLE_LIST, {project_id: pId, type: '1'}).then((data) => {
                this.eutFilter = data.records.projectUnitTypesList;
                this.getTypicalFloorPlans(pId)
            });
        },
        getTypicalFloorPlans(pId) {
            this.$store.dispatch(PROJECTTYPICALFLOORPLANS_SIMPLE_LIST, {project_id: pId}).then((data) => {
                this.tfpFilter = data.records.projectTypicalFloorPlansList;
            });
        },
        projectsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isProjectsLoaded = true;
                    that.$store
                        .dispatch(PROJECTS_SIMPLE_LIST, {
                            q: e.target.value,
                            type: that.locationRef
                        })
                        .then(data => {
                            that.projectsFilter = data.records.projectsList;
                            that.isProjectsLoaded = false;
                        });
                }
            }, 900);
        },
        projectsChange(project) {
            if (project) {
                this.editedItem.project_id = project.id;
                this.editedItem.no_of_bedrooms = '';
                this.editedItem.no_of_bathrooms = '';
                this.editedItem.built_up_area = '';
                this.editedItem.plot_area = '';
                this.builtUpAreaUnit = this.$store.state.auth.user.measurement_unit;
                this.plotAreaUnit = this.$store.state.auth.user.measurement_unit;
                this.locationRefModel = project.model
                if (project.developer) {
                    this.developersFilter = [];
                    this.editedItem.developer_id = project.developer_id
                    this.developersFilter = [project.developer];
                } else {
                    this.developersFilter = [];
                    this.editedItem.developer_id = null
                }

                if (!project.hasOwnProperty('commercial_title')) this.getInternalUnitTypes(project.id);
                this.editedItem.completion_status_id = project.completion_status_id;
                this.editedItem.publishing_status_id = this.editedItem.publishing_status_id ? this.editedItem.publishing_status_id : 4
                this.$store.state.listings.latitude = project.latitude;
                this.$store.state.listings.longitude = project.longitude;
                this.editedItem.location = project.location;
                this.editedItem.description = project.description;
                this.editedItem.meta_title = project.meta_title;
                this.editedItem.meta_keywords = project.meta_keywords;
                this.editedItem.focus_keywords = project.focus_keywords;
                this.editedItem.meta_description = project.meta_description;
                this.editedItem.amenity_ids = project.amenity_ids;
                this.editedItem.road_location_ids = project.road_location_ids;
                this.editedItem.view_ids = project.view_ids;
                this.editedItem.launch_date = project.launch_date;
                this.editedItem.registration_date = project.registration_date;
                this.editedItem.construction_started_date = project.construction_started_date;
                this.editedItem.anticipated_completion_date = project.anticipated_completion_date;
                this.editedItem.handover_date = project.handover_date;
            }
        },
        projectsChangeForDuplication(evt) {
            clearTimeout(this.apiTimeout)
            this.dupItem = ''
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (evt) {
                    that.isListingsLoaded = true;
                    that.$store
                        .dispatch(LISTINGS_SIMPLE_LIST, {
                            type: that.locationRef,
                            refId: evt
                        })
                        .then(data => {
                            that.listingsFilter = data.records.listingsList;
                            that.isListingsLoaded = false;
                        });
                }
            }, 900);
        },
        developersFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isDevelopersLoaded = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'Developers',
                            q: e.target.value
                        })
                        .then(data => {
                            that.developersFilter = that.developersFilter.concat(data.records.contactsList);
                            that.isDevelopersLoaded = false;
                        });
                }
            }, 900);
        },
        coolingProvidersFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isCoolingProvidersLoaded = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'Cooling Providers',
                            q: e.target.value
                        })
                        .then(data => {
                            that.coolingProvidersFilter = that.coolingProvidersFilter.concat(data.records.contactsList);
                            that.isCoolingProvidersLoaded = false;
                        });
                }
            }, 900);
        },
        yscKeyUp(evt) {
            if (!evt.target.value) {
                this.$store.state.listings.yearlyServiceCharges = ''
            } else {
                let val = evt.target.value;
                val = val.replace(/[^0-9\.]/g, '');
                if (val != "") {
                    let valArr = val.split('.');
                    valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                    val = valArr.join('.');
                }
                this.yscValue = val;

                if (
                    evt.target.value &&
                    this.editedItem.built_up_area &&
                    this.builtUpAreaUnit &&
                    this.yscMultiplyValue &&
                    this.yscMultiplyValue === "0"
                ) {
                    this.$store.state.listings.yearlyServiceCharges = this.unitCalculator(this.builtUpAreaUnit, this.yscValue.replace(/,(?=\d{3})/g, ''), this.editedItem.built_up_area)
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(this.$store.state.listings.yearlyServiceCharges)
                }

                if (
                    evt.target.value &&
                    this.editedItem.plot_area &&
                    this.plotAreaUnit &&
                    this.yscMultiplyValue &&
                    this.yscMultiplyValue === "1"
                ) {
                    this.$store.state.listings.yearlyServiceCharges = this.unitCalculator(this.plotAreaUnit, this.yscValue.replace(/,(?=\d{3})/g, ''), this.editedItem.plot_area)
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(this.$store.state.listings.yearlyServiceCharges)
                }
            }
        },
        yscMultiply(v) {
            if (!v) {
                this.$store.state.listings.yearlyServiceCharges = ''
            }
            if (v === '0') {
                if (
                    this.yscValue &&
                    this.editedItem.built_up_area &&
                    this.builtUpAreaUnit
                ) {
                    this.$store.state.listings.yearlyServiceCharges = this.unitCalculator(this.builtUpAreaUnit, this.yscValue.replace(/,(?=\d{3})/g, ''), this.editedItem.built_up_area);
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(this.$store.state.listings.yearlyServiceCharges)
                }
            }
            if (v === '1') {
                if (
                    this.yscValue &&
                    this.editedItem.plot_area &&
                    this.plotAreaUnit
                ) {
                    this.$store.state.listings.yearlyServiceCharges = this.unitCalculator(this.plotAreaUnit, this.yscValue.replace(/,(?=\d{3})/g, ''), this.editedItem.plot_area);
                    this.$store.state.listings.yearlyServiceCharges = this.addTSBV(this.$store.state.listings.yearlyServiceCharges)
                }
            }
        },
        mccKeyUp(evt) {
            if (!evt.target.value) {
                this.$store.state.listings.monthlyCoolingCharges = ''
            } else {
                let val = evt.target.value;
                val = val.replace(/[^0-9\.]/g, '');
                if (val != "") {
                    let valArr = val.split('.');
                    valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                    val = valArr.join('.');
                }
                this.mccValue = val;
                if (
                    evt.target.value &&
                    this.editedItem.built_up_area &&
                    this.builtUpAreaUnit &&
                    this.mccMultiplyValue &&
                    this.mccMultiplyValue === "0"
                ) {
                    this.$store.state.listings.monthlyCoolingCharges = this.unitCalculator(this.builtUpAreaUnit, this.mccValue.replace(/,(?=\d{3})/g, ''), this.editedItem.built_up_area)
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(this.$store.state.listings.monthlyCoolingCharges)
                }

                if (
                    evt.target.value &&
                    this.editedItem.plot_area &&
                    this.plotAreaUnit &&
                    this.mccMultiplyValue &&
                    this.mccMultiplyValue === "1"
                ) {
                    this.$store.state.listings.monthlyCoolingCharges = this.unitCalculator(this.plotAreaUnit, this.mccValue.replace(/,(?=\d{3})/g, ''), this.editedItem.plot_area)
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(this.$store.state.listings.monthlyCoolingCharges)
                }
            }
        },
        mccMultiply(v) {
            if (!v) {
                this.$store.state.listings.monthlyCoolingCharges = ''
            }
            if (v === '0') {
                if (
                    this.mccValue &&
                    this.editedItem.built_up_area &&
                    this.builtUpAreaUnit
                ) {
                    this.$store.state.listings.monthlyCoolingCharges = this.unitCalculator(this.builtUpAreaUnit, this.mccValue.replace(/,(?=\d{3})/g, ''), this.editedItem.built_up_area);
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(this.$store.state.listings.monthlyCoolingCharges)
                }
            }
            if (v === '1') {
                if (
                    this.mccValue &&
                    this.editedItem.plot_area &&
                    this.plotAreaUnit
                ) {
                    this.$store.state.listings.monthlyCoolingCharges = this.unitCalculator(this.plotAreaUnit, this.mccValue.replace(/,(?=\d{3})/g, ''), this.editedItem.plot_area);
                    this.$store.state.listings.monthlyCoolingCharges = this.addTSBV(this.$store.state.listings.monthlyCoolingCharges)
                }
            }
        },
        clearChargesValues() {
            this.yscValue = '';
            this.mccValue = '';
            this.$store.state.listings.monthlyCoolingCharges = ''
            this.$store.state.listings.yearlyServiceCharges = ''
        },
        ownersFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isOwnersLoaded = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'Real Estate Owners',
                            q: e.target.value
                        })
                        .then(data => {
                            that.ownersFilter = that.ownersFilter.concat(data.records.contactsList);
                            that.isOwnersLoaded = false;
                        });
                }
            }, 900);
        },
        kLFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isKLLoaded = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'Key Locations',
                            q: e.target.value
                        })
                        .then(data => {
                            that.kLFilter = that.kLFilter.concat(data.records.contactsList);
                            that.isKLLoaded = false;
                        });
                }
            }, 900);
        },
        tenantsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isTenantsLoaded = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'Tenant',
                            q: e.target.value
                        })
                        .then(data => {
                            that.tenantsFilter = that.tenantsFilter.concat(data.records.contactsList);
                            that.isTenantsLoaded = false;
                        });
                }
            }, 900);
        },
        iutChange(iut) {
            this.editedItem.internal_unit_type_id = iut.id;
            if (!this.editedItem.uni_no) {
                this.editedItem.built_up_area = iut.max_built_up_area;
                this.builtUpAreaUnit = iut.built_up_area_unit ? iut.built_up_area_unit : 'Square Feet';
                this.editedItem.built_up_area_length = iut.max_built_up_area_length;
                this.editedItem.built_up_area_width = iut.max_built_up_area_width;

                this.editedItem.plot_area = iut.max_plot_area;
                this.plotAreaUnit = iut.plot_area_unit ? iut.plot_area_unit : 'Square Feet';
                this.editedItem.plot_area_length = iut.max_plot_area_length;
                this.editedItem.plot_area_width = iut.max_plot_area_width;
            }
            this.editedItem.no_of_bathrooms = iut.no_of_bathrooms;
            this.editedItem.no_of_bedrooms = iut.no_of_bedrooms;
            this.editedItem.listing_features = iut.project_unit_type_features;
        },
        unitNumberKeyUp(e) {
            if (e.target.value) {
                this.isUnitNumberLoaded = true;
                this.$store
                    .dispatch(GET_PROJECTUNITDETAILS_DETAILS_BY_UNIT_NUMBER, {
                        project_id: this.editedItem.project_id,
                        unit_no: e.target.value
                    })
                    .then(data => {
                        this.unitNumberFilter = data.records.projectUnitDetailsList
                        this.isUnitNumberLoaded = false;
                    });
            }
        },
        unitNumberChange(data) {
            if (Object.keys(data).length !== 0) {
                this.editedItem.unit_no = data.unit_no
                this.ownersFilter = data.owners;
                this.editedItem.location_remark_ids = data.location_remark_ids;
                this.editedItem.owner_ids = data.owner_ids;
                this.coolingProvidersFilter = data.cooling_providers;
                this.editedItem.cooling_provider_ids = data.cooling_provider_ids;
                this.$store.state.listings.yearlyServiceCharges = data.yearly_service_charges
                this.$store.state.listings.monthlyCoolingCharges = data.monthly_cooling_charges
                this.editedItem.built_up_area = this.unitConverter(data.built_up_area_unit, data.built_up_area, this.$store.state.auth.user.measurement_unit);
                // this.builtUpAreaUnit = data.built_up_area_unit ? data.built_up_area_unit : 'Square Feet';
                this.editedItem.built_up_area_length = data.built_up_area_length;
                this.editedItem.built_up_area_width = data.built_up_area_width;

                this.editedItem.plot_area = this.unitConverter(data.plot_area_unit, data.plot_area, this.$store.state.auth.user.measurement_unit);
                // this.plotAreaUnit = data.plot_area_unit ? data.plot_area_unit : 'Square Feet';
                this.editedItem.plot_area_length = data.plot_area_length;
                this.editedItem.plot_area_width = data.plot_area_width;

                this.editedItem.floor = data.floor;
                this.editedItem.year_built = data.year_built;
                this.editedItem.street_name_no = data.street_name_no;
                this.editedItem.parking_no = data.parking_no;
                this.editedItem.no_of_parking = data.no_of_parking;
                this.editedItem.land_no = data.land_no;
                this.editedItem.official_residence_no = data.official_residence_no;
                this.editedItem.electricity_meter_no = data.electricity_meter_no;
                this.editedItem.gas_meter_no = data.gas_meter_no;
                this.editedItem.municipality_number = data.municipality_number;
            }
        },
        propertyTypeChange(obj) {
            console.log(obj)
            this.editedItem.project_id = '';
            if (obj) {
                this.editedItem.property_type_id = obj.id;
                if (obj.parent_id === 5) this.parentPropertyType = 'commercial'
                this.masterPropertyType = this.checkPropertyType(obj)
                if (this.masterPropertyType === '') {
                    this.locationRef = 'Project'
                } else {
                    this.locationRef = 'Development'
                }
            } else {
                this.parentPropertyType = ''
            }
        },
        propertyTypeChangeForDuplication(obj) {
            this.duplicatedItem.p_id = '';
            if (obj) {
                this.duplicatedItem.p_type_id = obj.id;
                this.masterPropertyType = this.checkPropertyType(obj)
                if (this.masterPropertyType === '') {
                    this.locationRef = 'Project'
                } else {
                    this.locationRef = 'Development'
                }
            }
        },
        updateStatus(status, id, column) {
            this.$store.dispatch(CLEAR_LISTINGS_ERRORS);
            let data = {
                status: status,
                column: column
            };
            this.$store.dispatch(UPDATE_LISTINGS_STATUS, {'slug': id, data: data})
        },
        getPercentageByAmount(e, amt, index) {
            if (e.target.value) {
                let value = parseFloat(e.target.value.replace(/,(?=\d{3})/g, ''));
                let amount = parseFloat(amt.replace(/,(?=\d{3})/g, ''));
                this.editedItem[index] = ((value / amount) * 100).toFixed(2)
            } else {
                this.editedItem[index] = ''
            }
        },
        getAmountByPercentage(e, amt, index) {
            if (e.target.value) {
                let value = parseFloat(e.target.value.replace(/,(?=\d{3})/g, ''));
                let amount = parseFloat(amt.replace(/,(?=\d{3})/g, ''));
                this.editedItem[index] = this.addTSBV(((value / 100) * amount).toFixed())
            } else {
                this.editedItem[index] = ''
            }
        },
        getPercentageByAmountWe(e, amt, index) {
            if (e) {
                let value = parseFloat(e.replace(/,(?=\d{3})/g, ''));
                let amount = parseFloat(amt.replace(/,(?=\d{3})/g, ''));
                this.editedItem[index] = ((value / amount) * 100).toFixed(2)
            } else {
                this.editedItem[index] = ''
            }
        },
        getAmountByPercentageWe(e, amt, index) {
            if (e) {
                let value = parseFloat(e.replace(/,(?=\d{3})/g, ''));
                let amount = parseFloat(amt.replace(/,(?=\d{3})/g, ''));
                this.editedItem[index] = this.addTSBV(((value / 100) * amount).toFixed())
            } else {
                this.editedItem[index] = ''
            }
        },
        listingsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isListingsLoaded = true;
                    that.$store
                        .dispatch(LISTINGS_SIMPLE_LIST, {
                            q: e.target.value
                        })
                        .then(data => {
                            that.listingsFilter = data.records.listingsList;
                            that.isListingsLoaded = false;
                        });
                }
            }, 900);
        },
        duplicateListing(id) {
            let that = this;
            this.$store.dispatch(SET_CONFIRMATION, {
                title: "Are you sure?", msg: "You want to duplicate this listing?", clr: 'red', callback: function () {
                    this.$store.dispatch(DUPLICATE_LISTING, {
                        'listing_id': id
                    }).then((res) => {
                        that.dupDialog = false
                        that.showEditDialog('edit', res.records)
                        that.loadData().then(response => {
                            that.items = response.items;
                            that.totalRecords = response.total;
                        });
                    });
                }
            });
        },
        developmentsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isDevelopmentsLoaded = true;
                    that.$store
                        .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                            q: e.target.value
                        })
                        .then(data => {
                            that.developmentsFilter = data.records.developmentsList;
                            that.isDevelopmentsLoaded = false;
                        });
                }
            }, 900);
        },
        updateSharedListings(type, item) {
            if (type === 'edit') {
                this.showEditDialog('edit', item)
            } else {
                this.showEditDialog('detail', item)
            }
        },
        showShareDialog() {
            this.shareDialog = true;
            this.listingSharingIds = this.$store.state.auth.user.listing_sharing_ids
        },
        shareListings() {
            this.$store.dispatch(CLEAR_LISTINGS_ERRORS);
            this.$store.dispatch(SHARE_LISTING, {'user_ids': this.listingSharingIds}).then(() => {
                this.$store.dispatch(VERIFY_AUTH)
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                this.shareDialog = false
            })
        },
        showTree(id, type) {
            this.pagination.page = 1
            if (id !== 0) {
                if (type === 'project') {
                    this.searchFormData.projectSearch = id
                } else {
                    this.searchFormData.developmentSearch = id
                }
                this.loadData().then(response => {
                    this.items = response.items;
                    this.totalRecords = response.total;
                });
            }
            if (type === 'development' || type === 'all') {
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.$store.dispatch(DEVELOPMENTS_WITH_COUNT, {
                    type: 'listings',
                    'id': id,
                    'get': type,
                    query: searchFormDataQuery
                })
                    .then((data) => {
                        this.treeItems = data.records.developmentsList
                    })
            }
        },
        emailListing(item) {
            this.$router.push({
                name: 'contacts',
                query: {share: 'listing', rid: item.id}
            })
        },
        orgPKeyUp(e) {
            if (e.target.value) {
                let val = e.target.value.replace(/,/g, '')
                let pop = this.editedItem.sale_paid_of_original_price
                let sop = parseFloat(val);
                pop = parseFloat(pop.replace(/,/g, ''));
                let msp = parseFloat(this.editedItem.max_sale_price.replace(/,/g, ''))
                if (msp > val) {
                    this.editedItem.sale_premium = this.addTSBV(msp - val)
                    this.editedItem.sale_discount = ''
                }
                if (msp < val) {
                    this.editedItem.sale_discount = this.addTSBV(msp - val)
                    this.editedItem.sale_premium = ''
                }
                if (msp == val) {
                    this.editedItem.sale_discount = ''
                    this.editedItem.sale_premium = ''
                }
                this.editedItem.sale_payable_now_developer = this.addTSBV(sop - pop);
            }
            this.editedItem.sale_original_price = this.addTS(e)
        },
        popKeyUp(e) {
            if (e.target.value) {
                // let val = e.target.value.replace(/,(?=\d{3})/g, '')
                let pop = this.editedItem.sale_paid_of_original_price
                let premium = this.editedItem.sale_premium
                let sop = this.editedItem.sale_original_price
                pop = parseFloat(pop.replace(/,/g, ''));
                premium = parseFloat(premium.replace(/,/g, ''));
                sop = parseFloat(sop.replace(/,/g, ''));
                this.editedItem.sale_payable_now = this.addTSBV(pop + premium);
                this.editedItem.sale_payable_now_developer = this.addTSBV(sop - pop);
            }
            this.editedItem.sale_paid_of_original_price = this.addTS(e);
            this.getPercentageByAmount(e, this.editedItem.sale_original_price, 'paid_of_original_price_percentage')
        },
        premiumKeyUp(e) {
            if (e.target.value) {
                // let val = e.target.value.replace(/,(?=\d{3})/g, '')
                let pop = this.editedItem.sale_paid_of_original_price
                let premium = this.editedItem.sale_premium
                pop = parseFloat(pop.replace(/,/g, ''));
                premium = parseFloat(premium.replace(/,/g, ''));
                this.editedItem.sale_payable_now = this.addTSBV(pop + premium);
                // this.editedItem.sale_payable_now_developer = this.addTSBV(op - val);
            }
            this.editedItem.sale_premium = this.addTS(e);
        },
        mspKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                let val = e.target.value.replace(/,(?=\d{3})/g, '')
                that.getAmountByPercentageWe("2", that.editedItem.max_sale_price, 'sale_agency_fee_buyer')
                that.editedItem.sale_agency_fee_buyer_percentage = "2"
                that.editedItem.max_sale_price = that.addTS(e)
            }, 900);
        },
        checkTrusteeFee(e) {
            if (e.target.value) {
                if (parseInt(e.target.value.replace(/,(?=\d{3})/g, '')) > 500000) this.editedItem.sale_trustee_office_fee = "4,400"
                if (parseInt(e.target.value.replace(/,(?=\d{3})/g, '')) < 500000) this.editedItem.sale_trustee_office_fee = "2,200"
            }
        },
        setUnitNo(e) {
            let domain = '';
            if (this.$store.state.auth.user.team_id === 1) {
                domain = 'dubricks.com'
            }
            if (this.$store.state.auth.user.team_id === 2) {
                domain = 'afsrealestate.com'
            }
            if (e) {
                this.showUnitNo = 1
                let url = 'https://' + domain + '/listing-price-report?ref=' + this.editedItem.id + '&tkn=6ad4f1r5gg4b1g5h4r552d6g1'
                this.generateReport(url)
            } else {
                this.showUnitNo = 0
                let url = 'https://' + domain + '/listing-price-report?ref=' + this.editedItem.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
                // this.reportLink = 'http://localhost:8080/listing-price-report?ref=' + this.editedItem.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
                this.generateReport(url)
            }
        },
        generateReport(link) {
            if (link) {
                this.$store.dispatch(SAVE_REPORT, {
                    url: link,
                    title: 'Listing Price Report',
                    description: '',
                })
                    .then(data => {
                        this.reportLink = 'https://reports.dubricks.com/' + data.records.u_id
                        // this.reportLink = 'http://urls.de/' + data.records.u_id
                        this.sumDialog = true
                    });
            }
        },
        copyLink() {
            let linkToCopy = document.querySelector('#report-link')
            linkToCopy.setAttribute('type', 'text')
            linkToCopy.select()
            try {
                let successful = document.execCommand('copy');
                if (successful) {
                    this.$store.dispatch(SET_SNACK, {clr: 'success', msg: 'Report Link Copied Successfully'});
                } else {
                    this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Report Link Not Copied'});
                }
            } catch (err) {
                this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Oops, Unable To Copy'});
            }
            linkToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        }
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.listings.errors
        }),
        pageCount() {
            // return this.totalRecords / this.pagination.rowsPerPage
            return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
        }
    }
};
export default listingsScript